{
  "auth": {
    "agree": "Tôi đồng ý với",
    "createAccount": "Tạo tài khoản",
    "createNewAccount": "Tạo tài khoản mới",
    "email": "Email",
    "login": "Đăng nhập",
    "password": "Mật khẩu",
    "recover_password": "Khôi phục mật khẩu",
    "sign_up": "Đăng ký",
    "keep_logged_in": "Giữ tôi đăng nhập",
    "termsOfUse": "Điều khoản sử dụng.",
    "reset_password": "Đặt lại mật khẩu",
    "first_name": "Họ",
    "last_name": "Tên"
  },
  "404": {
    "title": "Trang này không còn tồn tại.",
    "text": "Nếu bạn cảm thấy có điều gì đó không đúng, vui lòng gửi cho chúng tôi một tin nhắn tại ",
    "back_button": "Quay lại bảng điều khiển"
  },
  "typography": {
    "primary": "Kiểu chữ chính",
    "secondary": "Kiểu chữ phụ"
  },
  "dashboard": {
    "versions": "Phiên bản",
    "setupRemoteConnections": "Cài đặt kết nối từ xa",
    "currentVisitors": "Khách truy cập hiện tại",
    "navigationLayout": "Bố cục điều hướng",
    "topBarButton": "Thanh trên cùng",
    "sideBarButton": "Thanh bên"
  },
  "language": {
    "brazilian_portuguese": "Tiếng Bồ Đào Nha",
    "english": "Tiếng Anh",
    "spanish": "Tiếng Tây Ban Nha",
    "simplified_chinese": "Tiếng Trung giản thể",
    "persian": "Tiếng Ba Tư"
  },
  "menu": {
    "auth": "Xác thực",
    "buttons": "Nút bấm",
    "timelines": "Dòng thời gian",
    "dashboard": "Bảng điều khiển",
    "billing": "Thanh toán",
    "login": "Đăng nhập",
    "preferences": "Tùy chọn tài khoản",
    "payments": "Thanh toán",
    "settings": "Cài đặt ứng dụng",
    "pricing-plans": "Kế hoạch giá",
    "payment-methods": "Phương thức thanh toán",
    "signup": "Đăng ký",
    "recover-password": "Khôi phục mật khẩu",
    "recover-password-email": "Email khôi phục mật khẩu",
    "404": "404",
    "faq": "Câu hỏi thường gặp",
    "users": "Người dùng",
    "projects": "Dự án",
    "slot": "Chỗ gửi xe"
  },
  "button": {
    "add_slot": "Thêm chỗ gửi xe"
  },
  "messages": {
    "all": "Xem tất cả tin nhắn",
    "new": "Tin nhắn mới từ {name}",
    "mark_as_read": "Đánh dấu đã đọc"
  },
  "navbar": {
    "messageUs": "Yêu cầu phát triển web:",
    "repository": "Kho GitHub"
  },
  "notifications": {
    "all": "Xem tất cả thông báo",
    "less": "Xem ít thông báo hơn",
    "mark_as_read": "Đánh dấu đã đọc",
    "sentMessage": "đã gửi cho bạn một tin nhắn",
    "uploadedZip": "đã tải lên một tệp Zip mới với {type}",
    "startedTopic": "đã bắt đầu một chủ đề mới"
  },
  "user": {
    "language": "Thay đổi ngôn ngữ",
    "logout": "Đăng xuất",
    "profile": "Hồ sơ",
    "settings": "Cài đặt",
    "billing": "Thanh toán",
    "faq": "Câu hỏi thường gặp",
    "helpAndSupport": "Trợ giúp & hỗ trợ",
    "projects": "Dự án",
    "account": "Tài khoản",
    "explore": "Khám phá"
  },
  "treeView": {
    "basic": "Cơ bản",
    "icons": "Biểu tượng",
    "selectable": "Có thể chọn",
    "editable": "Có thể chỉnh sửa",
    "advanced": "Nâng cao"
  },
  "chat": {
    "title": "Trò chuyện",
    "sendButton": "Gửi"
  },
  "spacingPlayground": {
    "value": "Giá trị",
    "margin": "Lề",
    "padding": "Đệm"
  },
  "spacing": {
    "title": "Khoảng cách"
  },
  "cards": {
    "cards": "Thẻ",
    "fixed": "Cố định",
    "floating": "Nổi",
    "contentText": "Các sọc đặc biệt của ngựa vằn làm cho chúng trở thành một trong những loài động vật quen thuộc nhất với con người.",
    "contentTextLong": "Các sọc đặc biệt của ngựa vằn làm cho chúng trở thành một trong những loài động vật quen thuộc nhất với con người. Chúng sống ở nhiều môi trường khác nhau như đồng cỏ, thảo nguyên, rừng cây, bụi cây gai, núi, và các ngọn đồi ven biển. Nhiều yếu tố con người đã gây tác động nghiêm trọng đến quần thể ngựa vằn, đặc biệt là việc săn bắt để lấy da và sự phá hủy môi trường sống. Ngựa vằn Grévy và ngựa vằn núi đang có nguy cơ tuyệt chủng. Trong khi ngựa vằn đồng bằng phổ biến hơn, một phân loài, quagga, đã tuyệt chủng.",
    "rowHeight": "Chiều cao hàng",
    "title": {
      "default": "Mặc định",
      "withControls": "Có điều khiển",
      "customHeader": "Tiêu đề tùy chỉnh",
      "withoutHeader": "Không có tiêu đề",
      "withImage": "Có hình ảnh",
      "withTitleOnImage": "Tiêu đề trên hình ảnh",
      "withCustomTitleOnImage": "Tiêu đề tùy chỉnh trên hình ảnh",
      "withStripe": "Có sọc",
      "withBackground": "Có nền"
    },
    "button": {
      "main": "Chính",
      "cancel": "Hủy",
      "showMore": "Hiển thị thêm",
      "readMore": "Đọc thêm"
    },
    "link": {
      "edit": "Chỉnh sửa",
      "setAsDefault": "Đặt làm mặc định",
      "delete": "Xóa",
      "traveling": "Du lịch",
      "france": "Pháp",
      "review": "Đánh giá",
      "feedback": "Gửi phản hồi",
      "readFull": "Đọc toàn bộ bài viết",
      "secondaryAction": "Hành động phụ",
      "action1": "Hành động 1",
      "action2": "Hành động 2"
    }
  },
  "colors": {
    "themeColors": "Màu chủ đề",
    "extraColors": "Màu bổ sung",
    "gradients": {
      "basic": {
        "title": "Chuyển màu nút"
      },
      "hovered": {
        "title": "Chuyển màu nút khi di chuột",
        "text": "Làm sáng 15% áp dụng cho kiểu gốc (chuyển màu hoặc màu phẳng) cho trạng thái khi di chuột."
      },
      "pressed": {
        "title": "Chuyển màu nút khi nhấn",
        "text": "Làm tối 15% áp dụng cho kiểu gốc (chuyển màu hoặc màu phẳng) cho trạng thái khi nhấn."
      }
    }
  },
  "tabs": {
    "alignment": "Căn chỉnh tab",
    "overflow": "Tràn tab",
    "hidden": "Tab với thanh trượt ẩn",
    "grow": "Tab tăng trưởng"
  },
  "helpAndSupport": "Trợ giúp & hỗ trợ",
  "aboutVuesticAdmin": "Giới thiệu Vuestic Admin",
  "search": {
    "placeholder": "Tìm kiếm..."
  },
  "buttonSelect": {
    "dark": "Tối",
    "light": "Sáng"
  },
  "page": {
    "Slot": {
      "title": "Chỗ gửi xe",
      "name": "Tên Bãi Đỗ",
      "total": "Tổng Vị Trí",
      "open": "Mở Cửa",
      "close": "Đóng Cửa",
      "pricingPerHour": "Giá Theo Giờ",
      "alowBookingType": "Loại Bãi Đỗ",
      "destination": "Mô Tả",
      "city": "Thành Phố",
      "ward": "Xã, Phường",
      "district": "Quận, Huyện",
      "address": "Địa Chỉ",
      "delete": {
        "title": "Xóa Bãi Gửi Xe",
        "message": "Bạn Có Chắc Chắn Muốn Xóa Bãi Gửi Xe?",
        "okText": "Xóa",
        "cancelText": "Bỏ Qua"
      }
    },
    "Login": {
      "ForgotPassword": "Quên Mật Khẩu",
      "Signup": "Tạo mới tài khoản",
      "Login": "Đăng Nhập",
      "NewToLaca": "Bạn chưa có tài khoản trên LACA?",
      "keepLoggedIn": "Giữ tôi đăng nhập"
    }
  },
  "message": {
    "loginSuccess": "Đăng Nhập Thành Công",
    "createSuccess": "Tạo Tài Khoản Thành Công"
  },
  "errorMessage": {
    "VERIFY_GOOGLE_TOKEN_FAILED": "",
    "EXP_FIRST_LOGIN": "",
    "TENANT_NOTFOUND": "Tài khoản không tồn tại!",
    "TENANT_EXISTS": "Tài khoản đã tồn tại!",
    "VERIFY_TENANT_FAILED": "",
    "SLOT_NOTFOUND": "",
    "SLOT_BOOKING_NOTFOUND": "",
    "SLOT_BOOKING_EXISTS": "",
    "SLOT_BOOKING_FULL": "",
    "DOMAIN_UNAUTHORIZED": ""
  }
}
